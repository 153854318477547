<template>
  <table class="min-w-full border-collapse border border-gray-100 p-1">
    <thead :class="headerClass">
      <tr>
        <th
          v-for="(head, i) in headers"
          :key="i"
          scope="col"
          :class="[
            { 'border-collapse border border-gray-300': dividable },
            `text-${head.align || isRtl ? 'right' : 'left'}`,
          ]"
          class="px-4 py-1 text-sm font-semibold uppercase"
        >
          <slot :name="`header:${head.key}`" :item="head">
            {{ head.text }}
          </slot>
        </th>
      </tr>
    </thead>
    <tbody class="bg-white">
      <tr v-for="(row, i) in items" :key="i" :class="themeClass">
        <td
          v-for="(head, i) in headers"
          :key="i"
          scope="col"
          :class="[
            { 'border-collapse border border-gray-300': dividable },
            `text-${head.align || isRtl ? 'right' : 'left'}`,
          ]"
          class="px-4 py-1 text-xs font-medium hitespace-nowrap"
        >
          <slot :name="`item:${head.key}`" :item="row">
            {{ row[head.key] }}
          </slot>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'BaseTable',
  props: {
    headerClass: {
      type: String,
      default: '',
    },
    headers: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    themeType: {
      type: String,
      default: '',
    },
    dividable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    themeClass() {
      return this.themeType ? this.themeType + ':bg-gray-100' : ''
    },
  },
}
</script>

<style></style>
