<template>
  <div>
    <div class="m-5 bg-white">
      <div class="border border-gray-400">
        <div class="p-4 flex justify-between items-center">
          <h3 :class="{ 'text-rose': isLtr }" class="font-bold">
            {{ $t('title_repors') }}
            <span class="text-rose"> {{ $t('labratory_title') }}</span>
          </h3>
          <BackFa :text="$t('back')" />
        </div>
        <BaseTable
          class="text-right mb-6"
          header-class="bg-gray-50 text-gray-400 text-sm"
          :headers="headers"
          :items="items"
          theme-type="even"
        >
          <template #item:docter="{ item }">
            <div class="flex items-start">
              <input
                :class="{ 'ml-5': isRtl, 'mr-5': isLtr }"
                type="checkbox"
                class="form-checkbox mt-2 h-4 w-4"
                :checked="item.selected"
              />
              <div class="font-extralight">
                <h3 class="text-base">{{ item.docter }}</h3>
                <h3 class="text-xs font-light text-gray-600">
                  {{ item.specialty }}
                </h3>
              </div>
            </div>
          </template>
          <template #item:document="{ item }">
            <img width="32" class="mx-auto" :src="item.document" alt="" />
          </template>
          <template #item:allergy="{ item }">
            <span
              class="bg-cyan-400 text-white px-6 py-1 rounded-2xl shadow-lg"
              >{{ item.allergy }}</span
            >
          </template>
          <template #item:drugs>
            <i class="text-base text-gray-400 fas fa-ellipsis-v"></i>
          </template>
        </BaseTable>
      </div>

      <BaseTable
        :class="{ rtl: isLtr }"
        class="text-left my-6"
        header-class="bg-cyan-400 text-white text-sm"
        :headers="table2.headers"
        :items="table2.items"
        theme-type="even"
      >
      </BaseTable>
    </div>
  </div>
</template>

<script>
import BaseTable from '@/components/BaseTable'
import BackFa from '../components/back/BackFa.vue'

export default {
  name: 'Laboratory2',
  components: { BaseTable, BackFa },
  computed: {
    headers() {
      return [
        {
          key: 'docter',
          text: `${this.$t('doctor_reports')}/${this.$t('specialty_reports')}`,
        },
        {
          key: 'date',
          text: this.$t('date_reports'),
        },
        {
          key: 'subject',
          text: this.$t('subject_reports2'),
        },
        {
          key: 'allergy',
          text: this.$t('allergy_reports'),
        },
        {
          key: 'drugs',
          text: this.$t('list_drugs'),
          align: 'center',
        },
      ]
    },
    items() {
      return [
        {
          date: this.convertDate('2021-1-1'),
          specialty: this.$t('labratory_specialty'),
          docter: this.$t('labratory_docter'),
          subject: this.$t('labratory_subject'),
          selected: false,
          allergy: this.$t('allergy_labratory'),
        },
        {
          date: this.convertDate('2021-1-15'),
          specialty: this.$t('labratory_specialty'),
          docter: this.$t('labratory_docter'),
          subject: this.$t('labratory_subject2'),
          selected: false,
          allergy: this.$t('allergy_labratory3'),
        },
        {
          date: this.convertDate('2021-1-21'),
          specialty: this.$t('labratory_specialty'),
          docter: this.$t('labratory_docter'),
          subject: this.$t('labratory_subject3'),
          selected: false,
          allergy: this.$t('allergy_labratory2'),
        },

        //           "../assets/images/theme/edit-icon.png"
        // "../assets/images/theme/text-icon.png"
        // "../assets/images/theme/comment-icon.png"
        // "../assets/images/theme/text-left-icon.png"
        // "../assets/images/theme/edit-pen-icon.png"
        // "../assets/images/theme/circle-icon.png"
        // "../assets/images/theme/text-icon.png"
      ]
    },
  },
  data() {
    return {
      table2: {
        headers: [
          {
            key: 'observantions',
            text: 'OBSERVABTIONS',
            align: 'left',
          },
          {
            key: 'range',
            text: 'RANGE',
            align: 'left',
          },
          {
            key: 'unit',
            text: 'UNIT',
            align: 'left',
          },
          {
            key: 'result',
            text: 'RESULT',
            align: 'left',
          },
          {
            key: 'id',
            text: 'ID NUMBER',
            align: 'left',
          },
        ],
        items: [
          {
            range: '70-110',
            unit: 'mg/dl',
            result: '92',
            id: 'fasting blod sugar',
            observantions: '',
          },
          {
            range: '17-43',
            unit: 'mg/dl',
            result: '30',
            id: 'Ueia',
            observantions: '',
          },
          {
            range: '0.68-1.40',
            unit: 'mg/dl',
            result: '1.7',
            id: 'Creatinine',
            observantions: '',
          },
          {
            range: 'Female:2.3-6.1/Male:3/6-8.2',
            unit: 'mg/dl',
            result: '3.9',
            id: 'Uric Acid',
            observantions: '',
          },
          {
            range: 'Normal:up to 200',
            unit: 'mg/dl',
            result: '179',
            id: 'Cholesterol Total',
            observantions: '',
          },
          {
            range: 'Normal <200',
            unit: 'mg/dl',
            result: '93',
            id: 'Tryglycerides',
            observantions: '',
          },
          {
            range: '45-60',
            unit: 'mg/dl',
            result: '75',
            id: 'HDL Cholesterol',
            observantions: '',
          },
          {
            range: '45-60',
            unit: 'Ratio',
            result: '2.4',
            id: 'Cholesterol/HDL',
            observantions: '',
          },
          {
            range: '8.6/10.3',
            unit: 'Ratio',
            result: '8.6',
            id: 'Calcium.Ca',
            observantions: '',
          },
        ],
      },
    }
  },
}
</script>

<style></style>
